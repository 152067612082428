<template>
  <div>
    <admin-member-list-add-new
      v-if="$can('create', 'Admin Members')"
      :is-add-new-admin-sidebar-active.sync="isAddNewAdminSidebarActive"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <admins-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('common.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $tc('common.entries', perPage) }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                debounce="500"
                class="d-inline-block mr-1"
                :placeholder="$t('common.search') + '...'"
              />
              <b-button
                v-if="$can('create', 'Admin Members')"
                variant="primary"
                @click="isAddNewAdminSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('admin.addBtn') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refAdminListTable"
        class="position-relative"
        :items="fetchAdmins"
        responsive
        :fields="translatedTableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('message.empty')"
        :sort-desc.sync="isSortDirDesc"
        no-sort-reset
      >
        <template #table-busy>
          <div class="text-center mt-2">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>

        <!-- Column: admin -->
        <template #cell(name)="data">
          <b-media
            vertical-align="center"
            no-body
          >
            <b-media-aside>
              <b-avatar
                size="32"
                :src="data.item.imgProfile"
                :text="avatarText(data.item.name)"
                variant="light-primary"
                :to="{
                  name: 'master-admin-view',
                  params: { id: data.item.id },
                }"
              />
            </b-media-aside>
            <b-media-body class="align-self-center">
              <b-link
                :to="{
                  name: 'master-admin-view',
                  params: { id: data.item.id },
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
            </b-media-body>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <!-- <feather-icon
              :icon="resolveAdminRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveAdminRoleVariant(data.item.role)}`"
            /> -->
            <span class="align-text-top text-capitalize">{{
              title(data.item.role, '-')
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(isActive)="data">
          <b-badge
            pill
            :variant="resolveAdminActiveStatus(data.value)"
          >
            {{ data.value ? 'Active' : 'Inactive' }}
          </b-badge>
        </template>

        <template #cell(isVerified)="data">
          <b-badge :variant="resolveAdminVerifiedStatus(data.value)">
            {{ data.value ? 'Verified' : 'Unverified' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            dropup
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{ name: 'master-admin-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t('common.details') }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                $can('update', 'Admin Members') &&
                  data.item.role !== 'super-admin'
              "
              :to="{ name: 'master-admin-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('common.edit') }}</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('delete', 'Admin Members')"
              @click="confirmDelete(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('common.delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $tc('common.showing', dataMeta.of, {
                first: dataMeta.from,
                last: dataMeta.to,
                total: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalAdmin"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
  BMediaBody,
  BMediaAside,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, title } from '@core/utils/filter'
import store from '@/store'
// import AdminMembersListFilters from './AdminMembersListFilters.vue'
import useAdminMembersList from './useAdminMembersList'
import adminMemberStoreModule from '../adminMemberStoreModule'
import roleStoreModule from '../../role/roleStoreModule'
import AdminMemberListAddNew from './AdminMemberListAddNew.vue'
import 'animate.css'

export default {
  components: {
    // AdminMembersListFilters,
    AdminMemberListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BMediaBody,
    BMediaAside,
    BPagination,
    BSpinner,

    vSelect,
  },
  computed: {
    translatedTableColumns() {
      return this.tableColumns.map(column => ({
        ...column,
        label: this.$t(`admin.tableHeader.${column.key}`),
      }))
    },
  },
  methods: {
    confirmDelete({id, name}) {
      this.$swal({
        title: `Delete ${name} ?`,
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__shakeX',
        },
        buttonsStyling: false,
      }).then(async result => {
        try {
          if (result.value) {
            const res = await store.dispatch('master-admin/deleteAdmin', { id })

            if (res.data.code !== 400) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                timer: 2000,
                text: 'Your data has been deleted.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              this.refAdminListTable.refresh()
            } else {
              this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
              })
            }
          }
        } catch (e) {
          this.$swal({
            title: 'Error!',
            text: 'Something went wrong!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },

  setup() {
    const ADMIN_APP_STORE_MODULE_NAME = 'master-admin'
    const ROLE_APP_STORE_MODULE_NAME = 'master-role'

    // Register module
    if (!store.hasModule(ADMIN_APP_STORE_MODULE_NAME)) {
      store.registerModule(ADMIN_APP_STORE_MODULE_NAME, adminMemberStoreModule)
      store.registerModule(ROLE_APP_STORE_MODULE_NAME, roleStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ADMIN_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(ADMIN_APP_STORE_MODULE_NAME)
        store.unregisterModule(ROLE_APP_STORE_MODULE_NAME)
      }
    })

    const isAddNewAdminSidebarActive = ref(false)

    const statusOptions = [
      { text: 'Active', value: true },
      { text: 'Inactive', value: false },
    ]

    const {
      fetchAdmins,
      tableColumns,
      perPage,
      currentPage,
      totalAdmin,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAdminListTable,
      refetchData,

      // UI
      resolveAdminActiveStatus,
      resolveAdminVerifiedStatus,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useAdminMembersList()

    return {
      // Sidebar
      isAddNewAdminSidebarActive,

      fetchAdmins,
      tableColumns,
      perPage,
      currentPage,
      totalAdmin,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAdminListTable,
      refetchData,

      // Filter
      avatarText,
      title,

      // UI
      resolveAdminActiveStatus,
      resolveAdminVerifiedStatus,

      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
