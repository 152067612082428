import { ref, watch, computed } from '@vue/composition-api'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useAdminMembersList() {
  const toast = useToast()

  const refAdminListTable = ref(null)

  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'email', sortable: true },
    { key: 'role', sortable: true },
    { key: 'isActive' },
    { key: 'isVerified' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalAdmin = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('_id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refAdminListTable.value
      ? refAdminListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAdmin.value,
    }
  })

  const refetchData = () => {
    refAdminListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchAdmins = (ctx, callback) => {
    store
      .dispatch('master-admin/fetchAdmins', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { accounts, total } = response.data.data

        callback(accounts)
        totalAdmin.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Admin' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveAdminActiveStatus = status => {
    if (status) return 'light-success'
    return 'light-secondary'
  }

  const resolveAdminVerifiedStatus = status => {
    if (status) return 'success'
    return 'warning'
  }

  return {
    fetchAdmins,
    tableColumns,
    perPage,
    currentPage,
    totalAdmin,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAdminListTable,

    resolveAdminActiveStatus,
    resolveAdminVerifiedStatus,

    statusFilter,

    refetchData,
  }
}
