<template>
  <b-sidebar
    id="add-new-admin-sidebar"
    :visible="isAddNewAdminSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="val => $emit('update:is-add-new-admin-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t('admin.form.header') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group :label="$t('admin.form.name')" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="adminData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="email"
            rules="required|email"
          >
            <b-form-group :label="$t('admin.form.email')" label-for="email">
              <b-form-input
                id="email"
                v-model="adminData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Admin Role -->
          <validation-provider
            #default="validationContext"
            name="role"
            rules="required"
          >
            <b-form-group
              :label="$t('admin.form.role')"
              label-for="admin-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="adminData.role"
                class="text-capitalize"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :clearable="false"
                :loading="isRoleLoading"
                :reduce="val => val.value"
                input-id="admin-role"
                @open="fetch"
              >
                <template #spinner="{ loading }">
                  <b-spinner v-if="loading" variant="primary" small />
                  <span class="sr-only">Loading...</span>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 d-flex align-center justify-content-center"
              type="submit"
              :disabled="loadingState"
            >
              <template v-if="loadingState">
                <b-spinner type="grow" small class="mr-1" />
                Loading...
              </template>
              <span v-else>{{ $t('common.add') }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('common.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import { useAsyncState } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import useRoleList from '../../role/role-list/useRoleList'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: ['isAddNewAdminSidebarActive', 'roleOptions'],
    event: ['update:is-add-new-admin-sidebar-active', 'update:role-options'],
  },
  props: {
    isAddNewAdminSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },

  setup(props, { emit }) {
    const toast = useToast()
    const { fetchRoles, isRoleLoading, roleOptions } = useRoleList()

    const blankAdminData = {
      name: '',
      email: '',
      role: null,
    }

    const adminData = ref(JSON.parse(JSON.stringify(blankAdminData)))
    const resetuserData = () => {
      adminData.value = JSON.parse(JSON.stringify(blankAdminData))
    }

    const { state, isLoading: loadingState, execute: onSubmit } = useAsyncState(
      async () => {
        try {
          const response = await store.dispatch('master-admin/addAdmin', {
            data: adminData.value,
          })
          const { message } = response.data

          toast({
            component: ToastificationContent,
            props: {
              title: 'Yass!',
              text: message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          emit('refetch-data')
          emit('update:is-add-new-admin-sidebar-active', false)
        } catch (error) {
          const { message } = error.response.data

          toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Oops!',
                text: message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            { position: 'top-left' },
          )
        }
      },
      null,
      { immediate: false, resetOnExecute: false },
    )

    const fetch = () => {
      if (!roleOptions.value.length) {
        fetchRoles()
      }
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData,
    )

    return {
      adminData,
      onSubmit,
      roleOptions,
      fetchRoles,
      isRoleLoading,
      state,
      loadingState,
      fetch,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-admin-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
